import React from "react";
import { useJsqel } from "../../api/jsqel";
import { useStore } from "../../store/store";
import { Input, Card, Form, Button, message } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";

const centeredContainer = {
  maxWidth: "500px",
  minHeight: "100vh",
  display: "flex",
  margin: "auto",
};
const UserLoginForm = ({ history }) => {
  const { dispatch } = useStore();
  const next = (location) =>
    location &&
    location.search &&
    location.search.length &&
    location.search.startsWith("?next=")
      ? location.search.slice(6)
      : "";
  const afterLogin = ({ results, error }) => {
    if (results && results.token && results.role) {
      dispatch({ type: "LOGIN", ...results });
      message.success("Welcome !");
      history.push("/" + next(history.location));
    } else {
      message.error("Informations de connexions non valides");
      clear();
    }
  };
  const [{ loading }, refresh, clear] = useJsqel("buzzee/login", {
    sendItNow: false,
    XMLC_UserName: "",
    Password: "",
    callback: afterLogin,
  });

  return (
    <div style={centeredContainer}>
      <Card style={{ margin: "auto" }} loading={loading} title="Connexion">
        <Form onFinish={refresh}>
          <Form.Item
            name="XMLC_UserName"
            rules={[
              { required: true, message: "Please input your username!" },
              { min: 4, message: "At least 4 chars" },
            ]}
          >
            <Input
              prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              placeholder="Login"
            />
          </Form.Item>
          <Form.Item
            name="Password"
            rules={[
              { required: true, message: "Please input your Password!" },
              { min: 4, message: "At least 4 chars" },
            ]}
          >
            <Input
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              type="password"
              placeholder="Mot de passe"
            />
          </Form.Item>
          <Button type="primary" loading={loading} htmlType="submit">
            Se connecter
          </Button>
        </Form>
      </Card>
    </div>
  );
};
export default UserLoginForm;
