const insertEvent = async (events, setProgress, state, calendarName, insertEventTo, clear, insertSynchronisation) => {
    setProgress(0);
    if (events && events.length) {
        const nbOfItemsToInsert = events.length;
        // On trace la synchronisation réalisée
        insertSynchronisation({
            data: {
                userId: state.id,
                state: `Success. ${nbOfItemsToInsert} items processed (${calendarName})`
            }
        });
        let nbOfItemsInserted = 0;

        for (let event of events) {
            await insertEventTo({ event, userId: state.id });
            setProgress(nbOfItemsInserted / nbOfItemsToInsert);
        }
        clear();
    }
};

export default insertEvent;