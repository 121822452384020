import React from "react";

const GoogleButtonImage = ({ href }) => (
  <a href={href}>
    <img
      src={process.env.PUBLIC_URL + "btn_google_signin_light_normal_web.png"}
      alt="Google login"
    />
  </a>
);

export default GoogleButtonImage;
