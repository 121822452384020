import React from 'react';
import { Card, Button, Progress, Result } from "antd";

const MessageSynchro = ({calendarName, listOfCalendarEventsNumber, progress, onSuccess, onCancel}) => {
    return (
        <Card>
          <Result
            status="success"
            title={`${listOfCalendarEventsNumber} events imported from your ${calendarName} !`}
            subTitle={
              progress > 0 ? (
                <Progress percent={progress} />
              ) : (
                  `Now you have to choose if you want to copy them to your ${calendarName}.`
                )
            }
            extra={[
              <Button
                type="primary"
                key="copy"
                loading={progress > 0}
                onClick={onSuccess}
              >
                Copy the events
              </Button>,
              <Button key="cancel" onClick={onCancel}>
                Cancel
              </Button>,
            ]}
          />
        </Card>
    );
};

export default MessageSynchro;