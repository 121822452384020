import React, { useState } from "react";
import { Card, Button, Alert, Row, Col, DatePicker } from "antd";
import { useJsqel } from "../../api/jsqel";
import History from "./History";
import { useStore } from "../../store/store";
import userFeedback from "../widget/userFeedback";
import MessageSynchro from "./MessageSynchro";
import insertEvent from "./insertEvent";
const MessageInfo = ({ description }) => {
  return (
    <Alert
      message="Informational"
      description={description}
      type="info"
      showIcon
    />
  );
};

const Synchronization = () => {
  const { state } = useStore();
  const [progress, setProgress] = useState(0);
  const [startEvents, setStartEvents] = useState("");
  const [endEvents, setEndEvents] = useState("");

  const [synchronizationHistory, refresh] = useJsqel(
    "synchronization/listHistoryUser",
    {
      sendItNow: true,
    }
  );

  const [, insertSynchronisation] = useJsqel(`synchronization/create`, {
    sendItNow: false,
    callback: userFeedback("New synchronization", refresh),
  });

  const [listOfCalendarEvents, synchronizeCalendar, clearCalendarEvents] =
    useJsqel(`googleCalendar/getEventList`, {
      sendItNow: false,
      callback: userFeedback("Synchronization done", refresh),
    });

  const [, insertEventToGoogleAgendaAPI] = useJsqel(
    `googleCalendar/insertGoogle`,
    {
      sendItNow: false,
    }
  );

  const [listOfBuzzeeEvents, synchronizeBuzzee, clearBuzzeeEvents] = useJsqel(
    `buzzee/getEventList`,
    {
      sendItNow: false,
      callback: userFeedback("Synchronization done", refresh),
    }
  );

  const [, insertEventToBuzzeeAPI] = useJsqel(`buzzee/insertBuzzee`, {
    sendItNow: false,
  });

  const [userInfo] = useJsqel("utilisateur/utilisateurById", {
    sendItNow: true,
  });

  return (
    <>
      <Card>
        {userInfo.results &&
        userInfo.results[0] &&
        userInfo.results[0].token != null ? (
          userInfo.results[0].calendar != null ? (
            <Row justify="space-between">
              <Col>
                <Button
                  onClick={() =>
                    synchronizeCalendar({
                      userId: state.id,
                      startEvents,
                      endEvents,
                    })
                  }
                >
                  Synchronize Google
                </Button>
              </Col>
              <Col>
                Events planned between :&nbsp;
                <DatePicker
                  allowClear
                  format="DD-MM-YYYY"
                  onChange={(value) => setStartEvents(value)}
                />
              </Col>
              <Col>
                And &nbsp;
                <DatePicker
                  allowClear
                  format="DD-MM-YYYY"
                  onChange={(value) => setEndEvents(value)}
                />
              </Col>
              <Col>
                <Button
                  onClick={() =>
                    synchronizeBuzzee({
                      userId: state.id,
                      startEvents,
                      endEvents,
                    })
                  }
                >
                  Synchronize Buzzee
                </Button>
              </Col>
            </Row>
          ) : (
            <MessageInfo
              description={
                "In order to be able to synchronize, please choose a calendar in the Setting tab."
              }
            />
          )
        ) : (
          <MessageInfo
            description={
              "To be able to sync, please sign in to your Google Account in the Setting tab."
            }
          />
        )}
      </Card>
      <br />
      {listOfBuzzeeEvents &&
      listOfBuzzeeEvents.results &&
      listOfBuzzeeEvents.results.length ? (
        <MessageSynchro
          calendarName={"Buzzee calendar"}
          listOfCalendarEventsNumber={listOfBuzzeeEvents.results.length}
          progress={progress}
          onSuccess={() =>
            insertEvent(
              listOfBuzzeeEvents.results,
              setProgress,
              state,
              "Google",
              insertEventToGoogleAgendaAPI,
              clearBuzzeeEvents,
              insertSynchronisation
            )
          }
          onCancel={() => clearBuzzeeEvents()}
        />
      ) : (
        <></>
      )}
      {listOfCalendarEvents &&
      listOfCalendarEvents.results &&
      listOfCalendarEvents.results.length ? (
        <MessageSynchro
          calendarName={"Google calendar"}
          listOfCalendarEventsNumber={listOfCalendarEvents.results.length}
          progress={progress}
          onSuccess={() =>
            insertEvent(
              listOfCalendarEvents.results,
              setProgress,
              state,
              "Buzzee",
              insertEventToBuzzeeAPI,
              clearCalendarEvents,
              insertSynchronisation
            )
          }
          onCancel={() => clearCalendarEvents()}
        />
      ) : (
        <></>
      )}
      <br />
      <History list={synchronizationHistory.results} refresh={refresh} />
    </>
  );
};

export default Synchronization;
