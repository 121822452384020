import { setToken, removeToken } from "../api/jsqel";

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      setToken(action.token);
      return {
        id: action.user_id,
        username: action.username,
        token: action.token,
        role: action.role,
      };
    case "LOGOUT":
      removeToken();
      return { username: "", token: "" };
    case "UPDATE":
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const initialStore = { id: "", username: "" };

export { reducer, initialStore };
