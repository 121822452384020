import React from "react";
import { Route, Switch } from "react-router-dom";
import { Layout } from "antd";

import UserLoginForm from "../login/UserLoginForm";
import Synchronization from "../synchronization/Synchronization";
import Settings from "../settings/Settings";

import SideNavMenu from "./SideNavMenu";
import HeaderContent from "./HeaderContent";

const { Header, Footer, Content, Sider } = Layout;

const PrivateApp = () => {
  return (
    <Layout>
      <Header style={{ padding: 0 }}>
        <HeaderContent />
      </Header>

      <Layout style={{ minHeight: "100vh" }}>
        <Sider collapsible>
          <SideNavMenu />
        </Sider>

        <Content style={{ padding: "1rem" }}>
          <Switch>
            <Route exact path="/" component={Synchronization} />
            <Route exact path="/settings" component={Settings} />
            <Route component={UserLoginForm} />
          </Switch>
        </Content>
      </Layout>

      <Footer style={{ textAlign: "center" }}>
        Synchro Google Buzzee - <a href="https://camilab.co">Camilab 2022</a>{" "}
      </Footer>
    </Layout>
  );
};

export default PrivateApp;
