import React from "react";
import { Card, Tag, Table, Button } from "antd";

const columns = [
  {
    title: "Synchronized on",
    dataIndex: "date",
    key: "date",
    render: (item) =>
      `${new Date(item).toDateString()} At ${new Date(
        item
      ).toLocaleTimeString()}`,
  },
  {
    title: "State",
    dataIndex: "state",
    key: "state",
    render: (item) =>
      item === "error" ? (
        <Tag color="red">{item}</Tag>
      ) : (
        <Tag color="green">{item}</Tag>
      ),
  },
];

const History = ({ list, refresh }) => {
  return (
    <Card
      title="Synchronization history"
      extra={[
        <Button key="synchro-button" onClick={() => refresh()}>
          Refresh
        </Button>,
      ]}
    >
      <Table columns={columns} dataSource={list} />
    </Card>
  );
};

export default History;
