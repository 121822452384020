import { message } from 'antd';

const userFeedback = (feedbackMessage, callback, errorMessage) => ({ error }) => {
    if (error) {
        console.log(error.message)
         if (!error.message.includes('504')) {
            message.error(errorMessage || error.message);
        }
    } else {
        message.success(feedbackMessage || 'Success !')
        callback && callback()
    }
}

export default userFeedback
