import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Menu } from "antd";
import { SyncOutlined, SettingOutlined } from "@ant-design/icons";
import { useJsqel } from "../../api/jsqel";
import { useStore } from "../../store/store";

const SideNavMenu = ({ location }) => {
  useJsqel("auth/islogged", {
    sendItNow: true,
    callback: ({ results }) =>
      dispatch({ type: "UPDATE", payload: results && results[0] }),
  });
  const { dispatch } = useStore();

  return (
    <Menu
      theme="dark"
      mode="inline"
      selectedKeys={[location.pathname || "/settings"]}
      defaultSelectedKeys={["/settings"]}
    >
      <Menu.Item key="/">
        <Link to="/">
          <SyncOutlined />
          <span className="nav-text">Synchronizations</span>
        </Link>
      </Menu.Item>
      <Menu.Item key="/settings">
        <Link to="/settings">
          <SettingOutlined />
          <span className="nav-text">Settings</span>
        </Link>
      </Menu.Item>
    </Menu>
  );
};

export default withRouter(SideNavMenu);
