import React from "react";
import { useStore } from "../../store/store";
import { useJsqel } from "../../api/jsqel";
import { Row, Col, Button } from "antd";
import { useHistory } from "react-router-dom";

const headerStyleLogo = {
  color: "white",
  mawWidth: "200px",
  paddingLeft: "1rem",
};

const headerStyleUser = {
  color: "white",
  padding: "1rem",
};

const HeaderContent = () => {
  const history = useHistory();
  useJsqel("auth/islogged", {
    sendItNow: true,
    callback: ({ results, error }) =>
      results && results.length
        ? dispatch({ type: "UPDATE_USER_PROFILE", payload: results && results.length && results[0] })
        : history.push("/login"),
  });
  const { state, dispatch } = useStore();

  const handleLogout = (e) => dispatch({ type: "LOGOUT" }); // Clear token


  return (
    <Row align="middle" type="flex" justify="space-between">
      <Col span={8}>
        <h1 style={headerStyleLogo}>Buzzee Synchro</h1>
      </Col>
      <Col>
        <span style={headerStyleUser}> {state.username} </span>
        <Button onClick={handleLogout} style={{ marginRight: "1.25rem" }}>Log out</Button>
      </Col>
    </Row>
  );
};

export default HeaderContent;