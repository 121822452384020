import React from "react";
import { Card, Button, Select, Form, Switch } from "antd";
import { GoogleOutlined } from "@ant-design/icons";
import { useStore } from "../../store/store";
import { useJsqel } from "../../api/jsqel";
import userFeedback from "../widget/userFeedback";
import GoogleButtonImage from "./GoogleButtonImage";

const Settings = () => {
  const api_url = process.env.REACT_APP_BACKEND_URL || "http://localhost:5000/";
  const { state } = useStore();

  const [lists, refresh] = useJsqel(
    `AuthenticationAndSettings/getCalendarList`,
    {
      sendItNow: false,
    }
  );

  const [lastSynchroStats] = useJsqel(`synchronization/lastSynchroStats`, {
    sendItNow: true,
  });

  const [, setCalendarChannel] = useJsqel(`googleCalendar/setCalendar`, {
    sendItNow: false,
  });
  const [userInfo, refreshInfo] = useJsqel("utilisateur/utilisateurById", {
    sendItNow: true,
  });

  const [, updateItem] = useJsqel(`utilisateur/updateUtilisateur`, {
    sendItNow: false,
    callback: userFeedback("Updating settings", refreshInfo),
  });

  const [, googleLogout] = useJsqel("auth/googleLogout", {
    sendItNow: false,
    callback: userFeedback("Logout Google done", refreshInfo),
  });

  const [, enableActiveSync] = useJsqel(`buzzee/enableActiveSync`, {
    sendItNow: false,
    callback: userFeedback("enableActiveSync done", refresh),
  });
  const [, disableActiveSync] = useJsqel(`buzzee/disableActiveSync`, {
    sendItNow: false,
    callback: userFeedback("disableActiveSync done", refresh),
  });

  const { Option } = Select;

  const handleChangeCalendar = async (value) => {
    await updateItem({ data: { calendar: value, id: state.id } });
    await setCalendarChannel();
  };
  const handleChangeSynchronizationCalendar = (value) => {
    updateItem({ data: { synchronizationcalendar: value, id: state.id } });
  };
  const handleChangeSynchronizationBuzzee = (value) => {
    value
      ? enableActiveSync({ userId: state.id })
      : disableActiveSync({ userId: state.id });
    updateItem({ data: { synchronizationbuzzee: value, id: state.id } });
  };

  const handleDeleteGoogleAccount = () => googleLogout();

  return (
    <Card title="Settings">
      {userInfo.results[0] && userInfo.results[0].token ? (
        <>
          <p>
            <b>Your Google Account is signed in</b>
          </p>
          <Form.Item label="Choice of the agenda">
            <Select
              style={{ width: 250 }}
              defaultValue={userInfo.results[0].calendar}
              onChange={handleChangeCalendar}
              onClick={() => refresh()}
              placeholder="Choose a calendar to Synchronize"
            >
              {lists.results &&
                lists.results.length &&
                lists.results.map((item) => (
                  <Option value={item.id}>{item.summary}</Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label="Buzzee -> Google">
            {userInfo.results[0] &&
            userInfo.results[0].synchronizationcalendar === true ? (
              <Switch
                onChange={handleChangeSynchronizationCalendar}
                defaultChecked
              />
            ) : (
              <Switch onChange={handleChangeSynchronizationCalendar} />
            )}
            <span style={{ marginLeft: "1rem" }}>
              {lastSynchroStats.results.googleStats
                ? `Last sync on ${new Date(
                    lastSynchroStats.results.googleStats
                  ).toLocaleString()}`
                : "No sync"}
            </span>
          </Form.Item>
          <Form.Item label="Google -> Buzzee">
            {userInfo.results[0] &&
            userInfo.results[0].synchronizationbuzzee === true ? (
              <Switch
                onChange={handleChangeSynchronizationBuzzee}
                defaultChecked
              />
            ) : (
              <Switch onChange={handleChangeSynchronizationBuzzee} />
            )}
            <span style={{ marginLeft: "1rem" }}>
              {lastSynchroStats.results.buzzeeStats
                ? `Last sync on ${new Date(
                    lastSynchroStats.results.buzzeeStats
                  ).toLocaleString()}`
                : "No sync"}
            </span>
          </Form.Item>
          <Button
            type="primary"
            icon={<GoogleOutlined />}
            onClick={handleDeleteGoogleAccount}
          >
            Log out Google Account
          </Button>
        </>
      ) : (
        <GoogleButtonImage
          href={`${api_url}AuthenticationAndSettings/login?userid=${state.id}&XMLC_UserName=${state.username}`}
        />
      )}
    </Card>
  );
};

export default Settings;
